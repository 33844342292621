import React from "react";
import { Link } from "react-router-dom";

import {
  faSquareInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pittogramma from "../assets/logo_footer.png";

import "../css/Footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="footer-container row row-info pt-4 pb-2">
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div className="pb-1">
              <p className="title-text-footer mb-2">
                {t("main.header.company")}
              </p>
              <Link to={"/"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.home")}</p>
              </Link>
              <Link to={"/about-us"} className="list-inline text-start">
                <p className="mb-1">{t("main.header.aboutUs")}</p>
              </Link>
              <Link
                to={"https://rigsavecapital.netlify.app/#/login"}
                className="list-inline text-start"
              >
                <p className="mb-1">{t("main.header.icashly")}</p>
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <div className="pb-1">
              <p className="title-text-footer mb-2">
                {t("main.header.servicesDropdown.services")}
              </p>
              <Link to={"/fund-management"} className="list-inline text-start">
                <p className="mb-1">
                  {t("main.header.servicesDropdown.fundManagement")}
                </p>
              </Link>
              <Link to={"/certificates"} className="list-inline text-start">
                <p className="mb-1">
                  {t("main.header.servicesDropdown.certificates")}
                </p>
              </Link>
              <Link to={"/asset-management"} className="list-inline text-start">
                <p className="mb-1">
                  {t("main.header.servicesDropdown.gestionePat")}
                </p>
              </Link>
              <Link
                to={"/financial-consulting"}
                className="list-inline text-start"
              >
                <p className="mb-1">
                  {t("main.header.servicesDropdown.consulting")}
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.header.contacts")}
              </p>
            </div>
            <p className="pec-footer">
              <Link
                to={void 0}
                onClick={(e) =>
                  (window.location.href = "mailto:info@rigsavecapital.com")
                }
              >
                info@rigsavecapital.com
              </Link>
            </p>
            <p>C.so Giuseppe Zanardelli 38, Brescia (BS)</p>
            <p>(+39) 03 0375 6520</p>
          </div>
          <div className="col-lg-2 col-sm-6 col-md-6">
            <p className="title-text-footer mb-2">
              {t("main.header.workWithUs")}
            </p>
            <p className="mb-1">
              <Link to={"/contact-us"}>{t("main.header.sendCV")}</Link>
            </p>
          </div>
          <div className="col-lg-1 col-sm-6 col-md-6">
            <div>
              <p className="title-text-footer mb-2">
                {t("main.header.social")}
              </p>
            </div>
            <div className="d-flex">
              {/* <Link target="_blank" to={"https://www.facebook.com/rigsave"}>
                <p className="pe-2">
                  <FontAwesomeIcon
                    className="font-social"
                    icon={faSquareFacebook}
                  />
                </p>
              </Link> */}
              <Link
                target="_blank"
                to={"https://www.linkedin.com/company/rigsavecapital/"}
              >
                <p>
                  <FontAwesomeIcon className="font-social" icon={faLinkedin} />
                </p>
              </Link>
              <Link
                target="_blank"
                to={"https://www.instagram.com/rigsave_capital/"}
              >
                <p className="px-2">
                  <FontAwesomeIcon
                    className="font-social"
                    icon={faSquareInstagram}
                  />
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-1 col-sm-6 col-md-6 pittogramma-wrapper-footer d-flex justify-content-end">
            <div className="footer-ico-wrapper">
              <img height="50" src={pittogramma} alt="Andromeda pittogramma" />
            </div>
          </div>
        </div>
        <div className="d-lg-flex row row-footer-legal pt-3 pb-3">
          <div className="col-lg-6">
            <p className="title mb-1">Rigsave Capital LTD</p>
            <p>Copyright © Rigsave Capital LTD | {t("main.copyright")}</p>
            <p>
              {t("main.sede_legale")}: Old Bakery Street 171, Valletta, Malta
            </p>
            <p className="mb-0">{t("main.sedi_operative")}:</p>
            <p className="mb-0">
              37 Dolphin Court-M, Embassy Way, Ta' Xbiex, Malta
            </p>
            <p>C.so Giuseppe Zanardelli 38, 25121, Brescia, Italia</p>
          </div>
          <div className="col-lg-6 pp-cp">
            <div className="text-end mt-3">
              <Link
                to={"https://www.iubenda.com/privacy-policy/32717822"}
                className="nav-link px-5 text-end pb-3 font-weight-bold"
              >
                Privacy policy
              </Link>
              <Link
                to={
                  "https://www.iubenda.com/privacy-policy/32717822/cookie-policy"
                }
                className="nav-link px-5 text-end font-weight-bold"
              >
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

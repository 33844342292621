import React from "react";
import { useLocation } from "react-router-dom";

function NotFound() {
  const location = useLocation();

  return (
    <div className="container justify-content-center not-found mt-5 pb-5">
      <h3 className="m-0">ERROR 404</h3>
      <p className="pb-5">
        The requested URL {location.pathname} was not found on this server.
      </p>
    </div>
  );
}

export default NotFound;

import React from "react";

import { Trans, useTranslation } from "react-i18next";

import "../css/AboutUs.css";

import icon_1 from "../assets/icons/icon_about_us_1.png";
import icon_2 from "../assets/icons/icon_about_us_2.png";
import icon_3 from "../assets/icons/icon_about_us_3.png";
import icon_4 from "../assets/icons/icon_about_us_4.png";
import icon_5 from "../assets/icons/icon_about_us_5.png";

import giovanniG from "../assets/Giovanni_Finale.png";
import micheleB from "../assets/Michele_Finale.png";
import salvatoreG from "../assets/Salvatore_Finale.png";
import paulMagro from "../assets/Paul-Magro-6.png";
import jCamiller from "../assets/camilleri.jpg";
import aPolzot from "../assets/polzot.jpg";
import aSchmidt from "../assets/aSchmidt.jpg";
import gMoraglia from "../assets/moraglia.jpg";
import gCortesi from "../assets/cortesi.jpg";
import aSilvestri from "../assets/silvestri.jpg";
import gMifsud from "../assets/glen_mifsud.jpg";
import jpGauci from "../assets/jean_paul_gauci.jpg";
import rBuhagiar from "../assets/roberta_buhagiar.jpg";

import { Link } from "react-router-dom";

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about-us pt-5 mt-5">
      <div className="wrapper-struttura-gruppo container pt-5 pb-5 mb-4">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.aboutUs.ourStory")}
        </div>
        <div className="font-aboutUs">
          <Trans>{t("main.aboutUs.ourStoryText")}</Trans>
        </div>
        <div className="rigsave-custom-title text-center pt-5 pb-5">
          {t("main.aboutUs.ourPhilosophy")}
        </div>
        <div className="border-container vision">
          <div className="title">VISION</div>
          <div className="font-aboutUs">
            <Trans>{t("main.aboutUs.visionText")}</Trans>
          </div>
        </div>
        <div className="border-container mission mb-5">
          <div className="title">MISSION</div>
          <div className="font-aboutUs">
            <Trans>{t("main.aboutUs.missionText")}</Trans>
          </div>
        </div>
        <div className="values-container pt-5">
          <div className="rigsave-custom-title text-center pt-5 pb-5 mb-4">
            {t("main.aboutUs.ourValues")}
          </div>
          <div className=" d-lg-flex justify-content-center pb-5">
            <div className="d-lg-4 pe-5">
              <img src={icon_1} alt="Inclusion" />
              <div className="title">{t("main.aboutUs.inclusionTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.inclusionText")}
              </div>
            </div>
            <div className="d-lg-4 px-5">
              <img src={icon_2} alt="Transparency" />
              <div className="title">{t("main.aboutUs.transparencyTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.transparencyText")}
              </div>
            </div>
            <div className="d-lg-4 ps-5">
              <img src={icon_3} alt="Concreteness" />
              <div className="title">{t("main.aboutUs.concretenessTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.concretenessText")}
              </div>
            </div>
          </div>
          <div className=" d-lg-flex justify-content-center">
            <div className="d-lg-4 custom-left-padding">
              <img src={icon_4} alt="Sustainability" />
              <div className="title">
                {t("main.aboutUs.sustainabilityTitle")}
              </div>
              <div className="font-values">
                {t("main.aboutUs.sustainabilityText")}
              </div>
            </div>
            <div className="d-lg-4 custom-right-padding">
              <img src={icon_5} alt="Innovation" />
              <div className="title">{t("main.aboutUs.innovationTitle")}</div>
              <div className="font-values">
                {t("main.aboutUs.innovationText")}
              </div>
            </div>
          </div>
        </div>
        <div className="governance-container px-5 pt-5 pb-5">
          <div className="text-center title pb-5">Governance</div>
          <div className="d-lg-flex row-governance justify-content-center">
            <div className="col-lg-4 card-gov">
              <img src={micheleB} alt="Michele Basilicata" />
              <div className="bold-name">Michele Basilicata</div>
              <div>Executive director</div>
              <Link
                to={"https://www.linkedin.com/in/michele-basilicata-a3b5658b/"}
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-4 card-gov">
              <img src={salvatoreG} alt="Salvatore Gervasi" />
              <div className="bold-name">Salvatore Gervasi</div>
              <div>Executive director</div>
              <Link
                to={"https://www.linkedin.com/in/salvatore-gervasi-443404112/"}
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-4 card-gov">
              <img src={jCamiller} alt="Joseph Camilleri" />
              <div className="bold-name">Joseph Camilleri</div>
              <div>Indipendent director</div>
              <Link
                to={"https://www.linkedin.com/in/joseph-camilleri-75081223/"}
              >
                Linkedin
              </Link>
            </div>
          </div>
          <div className="text-center title pb-5 pt-5">
            Investment Committee
          </div>
          <div className="d-lg-flex row-investment justify-content-center">
            <div className="col-lg-4 card-gov">
              <img src={giovanniG} alt="Giovanni Gervasi" />
              <div className="bold-name">Giovanni Gervasi</div>
              <div>Portfolio manager</div>
              <Link
                to={
                  "https://www.linkedin.com/in/giovanni-gervasi-cefa-ba354a109/"
                }
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-4 card-gov">
              <img src={salvatoreG} alt="Salvatore Gervasi" />
              <div className="bold-name">Salvatore Gervasi</div>
              <div>Portfolio analyst</div>
              <Link
                to={"https://www.linkedin.com/in/salvatore-gervasi-443404112/"}
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-4  card-gov">
              <img src={gMifsud} alt="Glen Mifsud" />
              <div className="bold-name">Glen Mifsud</div>
              <div>Portfolio manager</div>
              <Link
                to={"https://www.linkedin.com/in/glen-mifsud-cfa-750820a1/"}
              >
                Linkedin
              </Link>
            </div>
          </div>
          <div className="text-center title pb-5 pt-5">Team</div>
          <div className="d-lg-flex justify-content-center team1">
            <div className="col-lg-3 px-5 card-gov">
              <img src={jpGauci} alt="Jean Paul Gauci" />
              <div className="bold-name">Jean Paul Gauci</div>
              <div>Risk manager and Valuation Officier</div>
              <Link to={"https://www.linkedin.com/in/jeanpaul-gauci/"}>
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={paulMagro} alt="Paul Magro" />
              <div className="bold-name">Paul Magro</div>
              <div>Money laundering reporting officier</div>
              <Link to={"https://www.linkedin.com/in/paulzmagro/"}>
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={rBuhagiar} alt="Roberta M. Buhagiar" />
              <div className="bold-name">Roberta M. Buhagiar</div>
              <div>Compliance officier</div>
              <Link
                to={"https://www.linkedin.com/in/roberta-buhagiar-86700765/"}
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={aPolzot} alt="Andrea Polzot" />
              <div className="bold-name">Andrea Polzot</div>
              <div>Chief financial officier</div>
              <Link to={"https://www.linkedin.com/in/andreapolzot/"}>
                Linkedin
              </Link>
            </div>
          </div>
          <div className="d-lg-flex justify-content-center pt-5 team2">
            <div className="col-lg-3 px-5 card-gov">
              <img src={aSchmidt} alt="Amelie Sophie Schmidt" />
              <div className="bold-name">Amelie Sophie Schmidt</div>
              <div>Compliance associate</div>
              <Link
                to={
                  "https://www.linkedin.com/in/amelie-sophie-schmidt-099520221/"
                }
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={gMoraglia} alt="Giulia Moraglia" />
              <div className="bold-name">Giulia Moraglia</div>
              <div>Relationship manager</div>
              <Link
                to={"https://www.linkedin.com/in/giulia-moraglia-7065ba176/"}
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={gCortesi} alt="Giovanni Vittorio Cortesi" />
              <div className="bold-name">Giovanni Vittorio Cortesi</div>
              <div>Relationship manager</div>
              <Link
                to={
                  "https://www.linkedin.com/in/giovanni-vittorio-cortesi-a2441914/"
                }
              >
                Linkedin
              </Link>
            </div>
            <div className="col-lg-3 px-5 card-gov">
              <img src={aSilvestri} alt="Andrea Silvestri" />
              <div className="bold-name">Andrea Silvestri</div>
              <div>Relationship associate</div>
              <Link
                to={"https://www.linkedin.com/in/andrea-silvestri-aa983a182/"}
              >
                Linkedin
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center pt-5 mt-4">
            <Link
              to={"/contact-us"}
              className="button-contact list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

import React from "react";

import { useTranslation } from "react-i18next";

import "../css/AssetManagement.css";
import { Link } from "react-router-dom";

import assetManagementImg from "../assets/photos/asset_management.png";

function AssetManagement() {
  const { t } = useTranslation();

  return (
    <div className="asset-management pt-5 mt-5 pb-5 mb-5">
      <div className="container container-title pt-5 pb-5 mb-4">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.assetManagement.title")}
        </div>
      </div>
      <div className="wrapper-asset-management container d-lg-flex justify-content-center px-5 pb-5">
        <img src={assetManagementImg} alt="Financial consulting" />
        <div className="text px-5">
          <p>{t("main.assetManagement.text")}</p>
          <div className="d-flex justify-content-center pt-4">
            <Link
              to={"/contact-us"}
              className="button-contact list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
      </div>
      <div className="container container-what-is pt-5 mt-5 pb-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.assetManagement.consultingFor")}
        </div>
        <div className="text">
          {t("main.assetManagement.consultingForText")}
        </div>
      </div>
      <div className="wrapper-asset-management container pt-5">
        <div className="rigsave-custom-title text-center pb-4 mb-5">
          {t("main.assetManagement.advantages")}
        </div>
        <div className="wrapper-divs d-lg-flex justify-content-center pb-5">
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.assetManagement.compProf")}</p>
            <p className="text">{t("main.assetManagement.compProfText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.assetManagement.commissioni")}</p>
            <p className="text">{t("main.assetManagement.commissioniText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">
              {t("main.assetManagement.investmentOptimization")}
            </p>
            <p className="text">
              {t("main.assetManagement.investmentOptimizationText")}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
          <Link
            to={"/contact-us"}
            className="button-contact final-a list-inline d-block text-center"
          >
            {t("main.home.contactUs")}
          </Link>
        </div>
      </div>
      <div className="container justify-content-center wrapper-invest-line mt-5">
        <div className="title text-center pt-4">
          {t("main.assetManagement.lineinvest")}
        </div>
        <div className="d-lg-flex wrapper-lines justify-content-center">
          <div className="justify-content-center pt-4 pb-5 single-line">
            <div className="subtitle pb-2">
              {t("main.assetManagement.conservative")}
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.financial_line")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.temp_orizz")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.risk")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
          </div>
          <div className="justify-content-center pt-4 pb-5 single-line">
            <div className="subtitle pb-2">
              {t("main.assetManagement.flexible")}
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.financial_line")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.temp_orizz")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.risk")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
          </div>
          <div className="justify-content-center pt-4 pb-5 single-line">
            <div className="subtitle pb-2">
              {t("main.assetManagement.dynamic")}
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.financial_line")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.temp_orizz")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
            <div className="pt-2">
              <p>{t("main.assetManagement.risk")}</p>
              <div className="wrapper-point d-flex">
                <div className="point filled"></div>
                <div className="point filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
                <div className="point not-filled"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AssetManagement;

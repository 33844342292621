import React from "react";

import { useTranslation } from "react-i18next";

import "../css/FinancialConsulting.css";
import { Link } from "react-router-dom";

import financialConsultingImg from "../assets/photos/financial_consulting.jpg";

function FinancialConsulting() {
  const { t } = useTranslation();

  return (
    <div className="financial-consulting pt-5 mt-5 pb-5 mb-5">
      <div className="container container-title pt-5 pb-5 mb-4">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.financialConsulting.title")}
        </div>
      </div>
      <div className="wrapper-financial-consulting container d-lg-flex justify-content-center px-5 pb-5">
        <img src={financialConsultingImg} alt="Financial consulting" />
        <div className="text px-5">
          <p>{t("main.financialConsulting.text")}</p>
          <div className="d-flex justify-content-center pt-4">
            <Link
              to={"/contact-us"}
              className="button-contact list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
      </div>
      <div className="container container-what-is pt-5 mt-5 pb-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.financialConsulting.consultingFor")}
        </div>
        <div className="text">
          {t("main.financialConsulting.consultingForText")}
        </div>
      </div>
      <div className="wrapper-financial-consulting container pt-5">
        <div className="rigsave-custom-title text-center pb-4 mb-5">
          {t("main.financialConsulting.advantages")}
        </div>
        <div className="wrapper-divs d-lg-flex justify-content-center pb-5">
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.financialConsulting.compProf")}</p>
            <p className="text">{t("main.financialConsulting.compProfText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.financialConsulting.commissioni")}</p>
            <p className="text">
              {t("main.financialConsulting.commissioniText")}
            </p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">
              {t("main.financialConsulting.investmentOptimization")}
            </p>
            <p className="text">
              {t("main.financialConsulting.investmentOptimizationText")}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
          <Link
            to={"/contact-us"}
            className="button-contact final-a list-inline d-block text-center"
          >
            {t("main.home.contactUs")}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default FinancialConsulting;

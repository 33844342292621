import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Suspense } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home from "pages/Home";
import ModalChoice from "components/ModalChoice";
import AboutUs from "pages/AboutUs";
import FundManagement from "pages/FundManagement";
import ContactUs from "pages/ContactUs";
import GoTop from "components/GoTop";
import FinancialConsulting from "pages/FinancialConsulting";
import AssetManagement from "pages/AssetManagement";
import Certificates from "pages/Certificates";
import Documents from "pages/Documents";
import ScrollToTop from "components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <ModalChoice />
      <div className="wrapper-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/fund-management" element={<FundManagement />} />
          <Route path="/fund-management/documents" element={<Documents />} />
          <Route
            path="/financial-consulting"
            element={<FinancialConsulting />}
          />
          <Route path="/asset-management" element={<AssetManagement />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <GoTop />
      <Footer />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}

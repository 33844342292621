import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import "../css/ModalChoice.css";

function ModalChoice() {
  const [open, setOpen] = useState(true);
  const closeModal = () => setOpen(false);

  const profileChoice = localStorage.getItem("investorType");

  useEffect(() => {
    if (!profileChoice) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [profileChoice]);

  return (
    <Popup closeOnDocumentClick={false} open={open}>
      <div className="actions justify-content-center text-center pt-4 pb-4 mt-4 mb-4">
        <div className="pb-5">
          <h2>Seleziona il tuo profilo</h2>
          Abbiamo bisogno di sapere se sei un investitore individuale o un
          professionista finanziario.
        </div>
        <button
          className="button mx-4"
          onClick={() => {
            localStorage.setItem("investorType", "istitutionalInvestor");
            closeModal();
          }}
        >
          Investitore istituzionale
        </button>
        <button
          className="button mx-4"
          onClick={() => {
            localStorage.setItem("investorType", "privateInvestor");
            closeModal();
          }}
        >
          Investitore privato
        </button>
      </div>
    </Popup>
  );
}

export default ModalChoice;

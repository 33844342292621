import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

import { Link } from "react-router-dom";

import "../css/Documents.css";
import { RotatingLines } from "react-loader-spinner";

function Documents() {
  const { t } = useTranslation();

  const [jsonObject, setJsonObject] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const [prospetto, setProspetto] = useState("");
  const [websiteDisclosure, setWebsiteDisclosure] = useState("");
  const [sottoscrizioneSoc, setSottoscrizioneSoc] = useState("");
  const [sottoscrizionePers, setSottoscrizionePers] = useState("");
  const [PRIIPSKID_R_IT, setPRIIPSKID_R_IT] = useState("");
  const [PRIIPSKID_I_IT, setPRIIPSKID_I_IT] = useState("");
  const [PRIIPSKID_R_EN, setPRIIPSKID_R_EN] = useState("");
  const [PRIIPSKID_I_EN, setPRIIPSKID_I_EN] = useState("");
  const [factsheetAprile2022, setFactsheetAprile2022] = useState("");
  const [factsheetMaggio2022, setFactsheetMaggio2022] = useState("");
  const [factsheetGiugno2022, setFactsheetGiugno2022] = useState("");
  const [factsheetLuglio2022_I, setFactsheetLuglio2022_I] = useState("");
  const [factsheetLuglio2022_R, setFactsheetLuglio2022_R] = useState("");
  const [factsheetAgosto_2022_I, setFactsheetAgosto_2022_I] = useState("");
  const [factsheetAgosto_2022_R, setFactsheetAgosto_2022_R] = useState("");
  const [factsheetSettembre_2022_I, setFactsheetSettembre_2022_I] =
    useState("");
  const [factsheetSettembre_2022_R, setFactsheetSettembre_2022_R] =
    useState("");
  const [factsheetOttobre_2022_I, setFactsheetOttobre_I] = useState("");
  const [factsheetOttobre_2022_R, setFactsheetOttobre_R] = useState("");
  const [factsheetNovembre_2022_I, setFactsheetNovembre_2022_I] = useState("");
  const [factsheetNovembre_2022_R, setFactsheetNovembre_2022_R] = useState("");
  const [factsheetDicembre_2022_I, setFactsheetDicembre_2022_I] = useState("");
  const [factsheetDicembre_2022_R, setFactsheetDicembre_2022_R] = useState("");
  const [factsheetGennaio_2023_I, setFactsheetGennaio_2023_I] = useState("");
  const [factsheetGennaio_2023_R, setFactsheetGennaio_2023_R] = useState("");
  const [factsheetFebbraio_2023_I, setFactsheetFebbraio_2023_I] = useState("");
  const [factsheetFebbraio_2023_R, setFactsheetFebbraio_2023_R] = useState("");
  const [factsheetMarzo_2023_I, setFactsheetMarzo_2023_I] = useState("");
  const [factsheetMarzo_2023_R, setFactsheetMarzo_2023_R] = useState("");
  const [factsheetAprile_2023_I, setFactsheetAprile_2023_I] = useState("");
  const [factsheetAprile_2023_R, setFactsheetAprile_2023_R] = useState("");
  const [factsheetMaggio_2023_I, setFactsheetMaggio_2023_I] = useState("");
  const [factsheetMaggio_2023_R, setFactsheetMaggio_2023_R] = useState("");
  const [factsheetGiugno_2023_I, setFactsheetGiugno_2023_I] = useState("");
  const [factsheetGiugno_2023_R, setFactsheetGiugno_2023_R] = useState("");
  const [factsheetLuglio_2023_I, setFactsheetLuglio_2023_I] = useState("");
  const [factsheetLuglio_2023_R, setFactsheetLuglio_2023_R] = useState("");
  const [factsheetAgosto_2023_I, setFactsheetAgosto_2023_I] = useState("");
  const [factsheetAgosto_2023_R, setFactsheetAgosto_2023_R] = useState("");
  const [factsheetSettembre_2023_I, setFactsheetSettembre_2023_I] =
    useState("");
  const [factsheetSettembre_2023_R, setFactsheetSettembre_2023_R] =
    useState("");
  const [factsheetOttobre_2023_I, setFactsheetOttobre_2023_I] = useState("");
  const [factsheetOttobre_2023_R, setFactsheetOttobre_2023_R] = useState("");
  const [factsheetNovembre_2023_I, setFactsheetNovembre_2023_I] = useState("");
  const [factsheetNovembre_2023_R, setFactsheetNovembre_2023_R] = useState("");
  const [factsheetDicembre_2023_I, setFactsheetDicembre_2023_I] = useState("");
  const [factsheetDicembre_2023_R, setFactsheetDicembre_2023_R] = useState("");
  const [factsheetGennaio_2024_I, setFactsheetGennaio_2024_I] = useState("");
  const [factsheetGennaio_2024_R, setFactsheetGennaio_2024_R] = useState("");
  const [factsheetFebbraio_2024_I, setFactsheetFebbraio_2024_I] = useState("");
  const [factsheetFebbraio_2024_R, setFactsheetFebbraio_2024_R] = useState("");
  const [factsheetMarch_2024_I, setFactsheetMarch_2024_I] = useState("");
  const [factsheetMarch_2024_R, setFactsheetMarch_2024_R] = useState("");

  const [factsheet_2024_Aprile_I_IT, setFactsheet_2024_Aprile_I_IT] =
    useState("");
  const [factsheet_2024_Aprile_R_IT, setFactsheet_2024_Aprile_R_IT] =
    useState("");
  const [factsheet_2024_Aprile_I_EN, setFactsheet_2024_Aprile_I_EN] =
    useState("");
  const [factsheet_2024_Aprile_R_EN, setFactsheet_2024_Aprile_R_EN] =
    useState("");

  const [factsheet_2024_Maggio_I_IT, setFactsheet_2024_Maggio_I_IT] =
    useState("");
  const [factsheet_2024_Maggio_R_IT, setFactsheet_2024_Maggio_R_IT] =
    useState("");
  const [factsheet_2024_Maggio_I_EN, setFactsheet_2024_Maggio_I_EN] =
    useState("");
  const [factsheet_2024_Maggio_R_EN, setFactsheet_2024_Maggio_R_EN] =
    useState("");
  const [factsheet_2024_Giugno_I_IT, setFactsheet_2024_Giugno_I_IT] =
    useState("");
  const [factsheet_2024_Giugno_R_IT, setFactsheet_2024_Giugno_R_IT] =
    useState("");
  const [factsheet_2024_Giugno_I_EN, setFactsheet_2024_Giugno_I_EN] =
    useState("");
  const [factsheet_2024_Giugno_R_EN, setFactsheet_2024_Giugno_R_EN] =
    useState("");
  const [factsheet_2024_Luglio_I_IT, setFactsheet_2024_Luglio_I_IT] =
    useState("");
  const [factsheet_2024_Luglio_R_IT, setFactsheet_2024_Luglio_R_IT] =
    useState("");
  const [factsheet_2024_Luglio_I_EN, setFactsheet_2024_Luglio_I_EN] =
    useState("");
  const [factsheet_2024_Luglio_R_EN, setFactsheet_2024_Luglio_R_EN] =
    useState("");
  const [factsheet_2024_Agosto_I_IT, setFactsheet_2024_Agosto_I_IT] =
    useState("");
  const [factsheet_2024_Agosto_R_IT, setFactsheet_2024_Agosto_R_IT] =
    useState("");
  const [factsheet_2024_Agosto_I_EN, setFactsheet_2024_Agosto_I_EN] =
    useState("");
  const [factsheet_2024_Agosto_R_EN, setFactsheet_2024_Agosto_R_EN] =
    useState("");
  const [factsheet_2024_Settembre_I_IT, setFactsheet_2024_Settembre_I_IT] =
    useState("");
  const [factsheet_2024_Settembre_R_IT, setFactsheet_2024_Settembre_R_IT] =
    useState("");
  const [factsheet_2024_Settembre_I_EN, setFactsheet_2024_Settembre_I_EN] =
    useState("");
  const [factsheet_2024_Settembre_R_EN, setFactsheet_2024_Settembre_R_EN] =
    useState("");
  const [factsheetOttobre_2024_I_IT, setFactsheetOttobre_2024_I_IT] =
    useState("");
  const [factsheetOttobre_2024_R_IT, setFactsheetOttobre_2024_R_IT] =
    useState("");
  const [annualReport_march_2022, setAnnualReport_march_2022] = useState("");
  const [annualReport_march_2023, setAnnualReport_march_2023] = useState("");
  const [semiAnnualReport_sett_2022, setSemiAnnualReport_sett_2022] =
    useState("");
  const [semiAnnualReport_sett_2023, setSemiAnnualReport_sett_2023] =
    useState("");
  const [FSAnnualMarch2024, setFSAnnualMarch2024] = useState("");
  const [semiAnnualReport_sett_2024, setSemiAnnualReport_sett_2024] =
    useState("");

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    const filteredItems = jsonObject.filter((pdf) =>
      pdf.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  async function downlaodFiles() {
    /*
    //FIRST VERSION OUT OF SCOPE
    setPRIIPSKID_R(
      (
        await getUrl({
          key: "fundManagement/PRIIPS-Manager-V2-KID-V2-2023-07-15-IT-LU2397781605-RIGSAVE-GLOBAL-EQUITY-VALUE-classe-R.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });
    setPRIIPSKID_I(
      (
        await getUrl({
          key: "fundManagement/PRIIPS-Manager-V2-KID-V2-2023-07-15-IT-LU2398290747-RIGSAVE-GLOBAL-EQUITY-VALUE-classe-I.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });
    //LATEST VERSION OF PRIIPSKID
    setPRIIPSKID_R_EN(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-EN-LU2397781605-RIGSAVE_GLOBAL_EQUITY_VALUE-class_R.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });
    setPRIIPSKID_I_IT(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-IT-LU2398290747-RIGSAVE_GLOBAL_EQUITY_VALUE-classe_I.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });
    setPRIIPSKID_R_IT(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-IT-LU2397781605-RIGSAVE_GLOBAL_EQUITY-VALUE-classe_R.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });
    setPRIIPSKID_I_EN(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-EN-LU2398290747-RIGSAVE_GLOBAL_EQUITY_VALUE-class_I.pdf",
        }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });*/
    await getUrl({
      key: "fundManagement/Rigsave-Fund-SICAV_Prospectus-July-2023-visa-stamped.pdf",
    }).then((result) => {
      setProspetto(result.url.toString());
    });
    await getUrl({
      key: "fundManagement/Website-disclosure-Rigsave-Global-Equity-Value-ARC-26.05.2023_VF_Clean.pdf",
    }).then((result) => {
      setWebsiteDisclosure(result.url.toString());
    });
    await getUrl({
      key: "fundManagement/Modulo-di-sottoscrizione-societa.pdf",
    }).then((result) => {
      setSottoscrizioneSoc(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/Modulo-di-sottoscrizione-persona-fisica.pdf",
    }).then((result) => {
      setSottoscrizionePers(result.url.toString());
    });
    await getUrl({
      key: "fundManagement/KIDDOC-2024-10-07-EN-00-2024-10-07-LU2397781605-RIGSAVE_SICAV-RIGSAVE_GLOBAL_EQUITY_VALUE-R.pdf",
    }).then((result) => {
      setPRIIPSKID_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/KIDDOC-2024-10-07-IT-00-2024-10-07-LU2398290747-RIGSAVE_SICAV-RIGSAVE_GLOBAL_EQUITY_VALUE-I.pdf",
    }).then((result) => {
      setPRIIPSKID_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/KIDDOC-2024-10-07-IT-00-2024-10-07-LU2397781605-RIGSAVE_SICAV-RIGSAVE_GLOBAL_EQUITY_VALUE-R.pdf",
    }).then((result) => {
      setPRIIPSKID_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/KIDDOC-2024-10-07-EN-00-2024-10-07-LU2398290747-RIGSAVE_SICAV-RIGSAVE_GLOBAL_EQUITY_VALUE-I.pdf",
    }).then((result) => {
      setPRIIPSKID_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/Rigsave-Factsheet-UCITS-05-2022_16.47.pdf",
    }).then((result) => {
      setFactsheetAprile2022(result.url.toString());
    });

    await getUrl({ key: "fundManagement/Termsheet-UCITS-06-2022.pdf" }).then(
      (result) => {
        setFactsheetMaggio2022(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/Termsheet-30-06-2022.pdf" }).then(
      (result) => {
        setFactsheetGiugno2022(result.url.toString());
      }
    );

    await getUrl({
      key: "fundManagement/Termsheet-Luglio-2022-Classe-I.pdf",
    }).then((result) => {
      setFactsheetLuglio2022_I(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/Termsheet-Luglio-2022-Classe-R.pdf",
    }).then((result) => {
      setFactsheetLuglio2022_R(result.url.toString());
    });

    await getUrl({ key: "fundManagement/factsheet31082022_I.pdf" }).then(
      (result) => {
        setFactsheetAgosto_2022_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31082022_R.pdf" }).then(
      (result) => {
        setFactsheetAgosto_2022_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet30092022_I.pdf" }).then(
      (result) => {
        setFactsheetSettembre_2022_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet30092022_R.pdf" }).then(
      (result) => {
        setFactsheetSettembre_2022_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31102022_I.pdf" }).then(
      (result) => {
        setFactsheetOttobre_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet30102022_R.pdf" }).then(
      (result) => {
        setFactsheetOttobre_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet30112022_I.pdf" }).then(
      (result) => {
        setFactsheetNovembre_2022_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet30112022_R.pdf" }).then(
      (result) => {
        setFactsheetNovembre_2022_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31122022_I.pdf" }).then(
      (result) => {
        setFactsheetDicembre_2022_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31122022_R.pdf" }).then(
      (result) => {
        setFactsheetDicembre_2022_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31012023_I.pdf" }).then(
      (result) => {
        setFactsheetGennaio_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet31012023_R.pdf" }).then(
      (result) => {
        setFactsheetGennaio_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet28022023_I.pdf" }).then(
      (result) => {
        setFactsheetFebbraio_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheet28022023_R.pdf" }).then(
      (result) => {
        setFactsheetFebbraio_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA31032023_I.pdf" }).then(
      (result) => {
        setFactsheetMarzo_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA31032023_R.pdf" }).then(
      (result) => {
        setFactsheetMarzo_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30042023_I.pdf" }).then(
      (result) => {
        setFactsheetAprile_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30042023_R.pdf" }).then(
      (result) => {
        setFactsheetAprile_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA31052023_I.pdf" }).then(
      (result) => {
        setFactsheetMaggio_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA31052023_R.pdf" }).then(
      (result) => {
        setFactsheetMaggio_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30062023_I.pdf" }).then(
      (result) => {
        setFactsheetGiugno_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30062023_R.pdf" }).then(
      (result) => {
        setFactsheetGiugno_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20230731_I.pdf" }).then(
      (result) => {
        setFactsheetLuglio_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20230731_R.pdf" }).then(
      (result) => {
        setFactsheetLuglio_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20230831_I.pdf" }).then(
      (result) => {
        setFactsheetAgosto_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20230831_R.pdf" }).then(
      (result) => {
        setFactsheetAgosto_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30092023_I.pdf" }).then(
      (result) => {
        setFactsheetSettembre_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA30092023_R.pdf" }).then(
      (result) => {
        setFactsheetSettembre_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231031_I.pdf" }).then(
      (result) => {
        setFactsheetOttobre_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231031_R.pdf" }).then(
      (result) => {
        setFactsheetOttobre_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231130_I.pdf" }).then(
      (result) => {
        setFactsheetNovembre_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231130_R.pdf" }).then(
      (result) => {
        setFactsheetNovembre_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231231_I.pdf" }).then(
      (result) => {
        setFactsheetDicembre_2023_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20231231_R.pdf" }).then(
      (result) => {
        setFactsheetDicembre_2023_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20240131_I.pdf" }).then(
      (result) => {
        setFactsheetGennaio_2024_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20240131_R.pdf" }).then(
      (result) => {
        setFactsheetGennaio_2024_R(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20240229_I.pdf" }).then(
      (result) => {
        setFactsheetFebbraio_2024_I(result.url.toString());
      }
    );

    await getUrl({ key: "fundManagement/factsheetITA20240229_R.pdf" }).then(
      (result) => {
        setFactsheetFebbraio_2024_R(result.url.toString());
      }
    );

    await getUrl({
      key: "fundManagement/factsheetITA20240331_I.pdf",
    }).then((result) => {
      setFactsheetMarch_2024_I(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240331_R.pdf",
    }).then((result) => {
      setFactsheetMarch_2024_R(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240430_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Aprile_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240430_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Aprile_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240430_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Aprile_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240430_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Aprile_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240531_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Maggio_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240531_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Maggio_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240531_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Maggio_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240531_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Maggio_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240630_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Giugno_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240630_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Giugno_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240630_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Giugno_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240630_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Giugno_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240731_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Luglio_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240731_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Luglio_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240731_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Luglio_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240731_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Luglio_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240831_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Agosto_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240831_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Agosto_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240831_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Agosto_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240831_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Agosto_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240930_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Settembre_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheetITA20240930_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Settembre_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240930_I.pdf",
    }).then((result) => {
      setFactsheet_2024_Settembre_I_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20240930_R.pdf",
    }).then((result) => {
      setFactsheet_2024_Settembre_R_EN(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20241031Iactual.pdf",
    }).then((result) => {
      setFactsheetOttobre_2024_I_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/factsheet20241031Ractual.pdf",
    }).then((result) => {
      setFactsheetOttobre_2024_R_IT(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/AR_RIGSAVE-SICAV_31.03.22-signed.pdf",
    }).then((result) => {
      setAnnualReport_march_2022(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/Rigsave-Fund-SICAV-audited-FS-31032023.pdf",
    }).then((result) => {
      setAnnualReport_march_2023(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/1244_20220930_02S-W_ANG_RIGSAVE-FUND-SICAV.pdf",
    }).then((result) => {
      setSemiAnnualReport_sett_2022(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/unaudited-semi-annual-report-30-september-2023.pdf",
    }).then((result) => {
      setSemiAnnualReport_sett_2023(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/FS_RIGSAVE_FUND_SICAV_03_2024.pdf",
    }).then((result) => {
      setFSAnnualMarch2024(result.url.toString());
    });

    await getUrl({
      key: "fundManagement/1244_20240930_02S-W_ANG_RIGSAVE_FUND_SICAV.pdf",
    }).then((result) => {
      setSemiAnnualReport_sett_2024(result.url.toString());
    });
  }

  useEffect(() => {
    Promise.all([downlaodFiles()]).then((responses) => {
      const tempArrayResults = [
        { name: "Prospetto", url: prospetto },
        { name: "Website Disclosure", url: websiteDisclosure },
        { name: "Modulo di sottoscrizione - Società", url: sottoscrizioneSoc },
        {
          name: "Modulo di sottoscrizione - Persona fisica",
          url: sottoscrizionePers,
        },
        { name: "PRIIPS KID IT - Classe R", url: PRIIPSKID_R_IT },
        { name: "PRIIPS KID IT - Classe I", url: PRIIPSKID_I_IT },
        { name: "PRIIPS KID EN - Classe R", url: PRIIPSKID_R_EN },
        { name: "PRIIPS KID EN - Classe I", url: PRIIPSKID_I_EN },
        { name: "Factsheet Aprile 2022", url: factsheetAprile2022 },
        { name: "Factsheet Maggio 2022", url: factsheetMaggio2022 },
        { name: "Factsheet Giugno 2022", url: factsheetGiugno2022 },
        {
          name: "Factsheet Luglio 2022 - Classe I",
          url: factsheetLuglio2022_I,
        },
        {
          name: "Factsheet Luglio 2022 - Classe R",
          url: factsheetLuglio2022_R,
        },
        {
          name: "Factsheet Agosto 2022 - Classe I",
          url: factsheetAgosto_2022_I,
        },
        {
          name: "Factsheet Agosto 2022 - Classe R",
          url: factsheetAgosto_2022_R,
        },
        {
          name: "Factsheet Settembre 2022 - Classe I",
          url: factsheetSettembre_2022_I,
        },
        {
          name: "Factsheet Settembre 2022 - Classe R",
          url: factsheetSettembre_2022_R,
        },
        {
          name: "Factsheet Ottobre 2022 - Classe I",
          url: factsheetOttobre_2022_I,
        },
        {
          name: "Factsheet Ottobre 2022 - Classe R",
          url: factsheetOttobre_2022_R,
        },
        {
          name: "Factsheet Novembre 2022 - Classe I",
          url: factsheetNovembre_2022_I,
        },
        {
          name: "Factsheet Novembre 2022 - Classe R",
          url: factsheetNovembre_2022_R,
        },
        {
          name: "Factsheet Dicembre 2022 - Classe I",
          url: factsheetDicembre_2022_I,
        },
        {
          name: "Factsheet Dicembre 2022 - Classe R",
          url: factsheetDicembre_2022_R,
        },
        {
          name: "Factsheet Gennaio 2023 - Classe I",
          url: factsheetGennaio_2023_I,
        },
        {
          name: "Factsheet Gennaio 2023 - Classe R",
          url: factsheetGennaio_2023_R,
        },
        {
          name: "Factsheet Febbraio 2023 - Classe I",
          url: factsheetFebbraio_2023_I,
        },
        {
          name: "Factsheet Febbraio 2023 - Classe R",
          url: factsheetFebbraio_2023_R,
        },
        { name: "Factsheet Marzo 2023 - Classe I", url: factsheetMarzo_2023_I },
        { name: "Factsheet Marzo 2023 - Classe R", url: factsheetMarzo_2023_R },
        {
          name: "Factsheet Aprile 2023 - Classe I",
          url: factsheetAprile_2023_I,
        },
        {
          name: "Factsheet Aprile 2023 - Classe R",
          url: factsheetAprile_2023_R,
        },
        {
          name: "Factsheet Maggio 2023 - Classe I",
          url: factsheetMaggio_2023_I,
        },
        {
          name: "Factsheet Maggio 2023 - Classe R",
          url: factsheetMaggio_2023_R,
        },
        {
          name: "Factsheet Giugno 2023 - Classe I",
          url: factsheetGiugno_2023_I,
        },
        {
          name: "Factsheet Giugno 2023 - Classe R",
          url: factsheetGiugno_2023_R,
        },
        {
          name: "Factsheet Luglio 2023 - Classe I",
          url: factsheetLuglio_2023_I,
        },
        {
          name: "Factsheet Luglio 2023 - Classe R",
          url: factsheetLuglio_2023_R,
        },
        {
          name: "Factsheet Agosto 2023 - Classe I",
          url: factsheetAgosto_2023_I,
        },
        {
          name: "Factsheet Agosto 2023 - Classe R",
          url: factsheetAgosto_2023_R,
        },
        {
          name: "Factsheet Settembre 2023 - Classe I",
          url: factsheetSettembre_2023_I,
        },
        {
          name: "Factsheet Settembre 2023 - Classe R",
          url: factsheetSettembre_2023_R,
        },
        {
          name: "Factsheet Ottobre 2023 - Classe I",
          url: factsheetOttobre_2023_I,
        },
        {
          name: "Factsheet Ottobre 2023 - Classe R",
          url: factsheetOttobre_2023_R,
        },
        {
          name: "Factsheet Novembre 2023 - Classe I",
          url: factsheetNovembre_2023_I,
        },
        {
          name: "Factsheet Novembre 2023 - Classe R",
          url: factsheetNovembre_2023_R,
        },
        {
          name: "Factsheet Dicembre 2023 - Classe I",
          url: factsheetDicembre_2023_I,
        },
        {
          name: "Factsheet Dicembre 2023 - Classe R",
          url: factsheetDicembre_2023_R,
        },
        {
          name: "Factsheet Gennaio 2024 - Classe I",
          url: factsheetGennaio_2024_I,
        },
        {
          name: "Factsheet Gennaio 2024 - Classe R",
          url: factsheetGennaio_2024_R,
        },
        {
          name: "Factsheet Febbraio 2024 - Classe I",
          url: factsheetFebbraio_2024_I,
        },
        {
          name: "Factsheet Febbraio 2024 - Classe R",
          url: factsheetFebbraio_2024_R,
        },
        { name: "Factsheet Marzo 2024 - Classe I", url: factsheetMarch_2024_I },
        { name: "Factsheet Marzo 2024 - Classe R", url: factsheetMarch_2024_R },
        {
          name: "Factsheet Aprile 2024 IT - Classe I",
          url: factsheet_2024_Aprile_I_IT,
        },
        {
          name: "Factsheet Aprile 2024 IT - Classe R",
          url: factsheet_2024_Aprile_R_IT,
        },
        {
          name: "Factsheet Aprile 2024 EN - Classe I",
          url: factsheet_2024_Aprile_I_EN,
        },
        {
          name: "Factsheet Aprile 2024 EN - Classe R",
          url: factsheet_2024_Aprile_R_EN,
        },
        {
          name: "Factsheet Maggio 2024 IT - Classe I",
          url: factsheet_2024_Maggio_I_IT,
        },
        {
          name: "Factsheet Maggio 2024 IT - Classe R",
          url: factsheet_2024_Maggio_R_IT,
        },
        {
          name: "Factsheet Maggio 2024 EN - Classe I",
          url: factsheet_2024_Maggio_I_EN,
        },
        {
          name: "Factsheet Maggio 2024 EN - Classe R",
          url: factsheet_2024_Maggio_R_EN,
        },
        {
          name: "Factsheet Giugno 2024 IT - Classe I",
          url: factsheet_2024_Giugno_I_IT,
        },
        {
          name: "Factsheet Giugno 2024 IT - Classe R",
          url: factsheet_2024_Giugno_R_IT,
        },
        {
          name: "Factsheet Giugno 2024 EN - Classe I",
          url: factsheet_2024_Giugno_I_EN,
        },
        {
          name: "Factsheet Giugno 2024 EN - Classe R",
          url: factsheet_2024_Giugno_R_EN,
        },
        {
          name: "Factsheet Luglio 2024 IT - Classe I",
          url: factsheet_2024_Luglio_I_IT,
        },
        {
          name: "Factsheet Luglio 2024 IT - Classe R",
          url: factsheet_2024_Luglio_R_IT,
        },
        {
          name: "Factsheet Luglio 2024 EN - Classe I",
          url: factsheet_2024_Luglio_I_EN,
        },
        {
          name: "Factsheet Luglio 2024 EN - Classe R",
          url: factsheet_2024_Luglio_R_EN,
        },
        {
          name: "Factsheet Agosto 2024 IT - Classe I",
          url: factsheet_2024_Agosto_I_IT,
        },
        {
          name: "Factsheet Agosto 2024 IT - Classe R",
          url: factsheet_2024_Agosto_R_IT,
        },
        {
          name: "Factsheet Agosto 2024 EN - Classe I",
          url: factsheet_2024_Agosto_I_EN,
        },
        {
          name: "Factsheet Agosto 2024 EN - Classe R",
          url: factsheet_2024_Agosto_R_EN,
        },
        {
          name: "Factsheet Settembre 2024 EN - Classe I",
          url: factsheet_2024_Settembre_I_EN,
        },
        {
          name: "Factsheet Settembre 2024 EN - Classe R",
          url: factsheet_2024_Settembre_R_EN,
        },
        {
          name: "Factsheet Settembre 2024 IT - Classe I",
          url: factsheet_2024_Settembre_I_IT,
        },
        {
          name: "Factsheet Settembre 2024 IT - Classe R",
          url: factsheet_2024_Settembre_R_IT,
        },
        {
          name: "Factsheet Ottobre 2024 - Classe I",
          url: factsheetOttobre_2024_I_IT,
        },
        {
          name: "Factsheet Ottobre 2024 - Classe R",
          url: factsheetOttobre_2024_R_IT,
        },
        { name: "Annual Report - Marzo 2022", url: annualReport_march_2022 },
        { name: "Annual Report - Marzo 2023", url: annualReport_march_2023 },
        {
          name: "Semi Annual Report - Settembre 2022",
          url: semiAnnualReport_sett_2022,
        },
        {
          name: "Semi Annual Report - Settembre 2023",
          url: semiAnnualReport_sett_2023,
        },
        { name: "Annual report - Marzo 2024", url: FSAnnualMarch2024 },
        {
          name: "Semi Annual Report - Settembre 2024",
          url: semiAnnualReport_sett_2024,
        },
      ];
      setFilteredData(tempArrayResults);
      setJsonObject(tempArrayResults);
    });
  }, [
    FSAnnualMarch2024,
    PRIIPSKID_I_EN,
    PRIIPSKID_I_IT,
    PRIIPSKID_R_EN,
    PRIIPSKID_R_IT,
    annualReport_march_2022,
    annualReport_march_2023,
    factsheetAgosto_2022_I,
    factsheetAgosto_2022_R,
    factsheetAgosto_2023_I,
    factsheetAgosto_2023_R,
    factsheetAprile2022,
    factsheetAprile_2023_I,
    factsheetAprile_2023_R,
    factsheetDicembre_2022_I,
    factsheetDicembre_2022_R,
    factsheetDicembre_2023_I,
    factsheetDicembre_2023_R,
    factsheetFebbraio_2023_I,
    factsheetFebbraio_2023_R,
    factsheetFebbraio_2024_I,
    factsheetFebbraio_2024_R,
    factsheetGennaio_2023_I,
    factsheetGennaio_2023_R,
    factsheetGennaio_2024_I,
    factsheetGennaio_2024_R,
    factsheetGiugno2022,
    factsheetGiugno_2023_I,
    factsheetGiugno_2023_R,
    factsheetLuglio2022_I,
    factsheetLuglio2022_R,
    factsheetLuglio_2023_I,
    factsheetLuglio_2023_R,
    factsheetMaggio2022,
    factsheetMaggio_2023_I,
    factsheetMaggio_2023_R,
    factsheetMarch_2024_I,
    factsheetMarch_2024_R,
    factsheetMarzo_2023_I,
    factsheetMarzo_2023_R,
    factsheetNovembre_2022_I,
    factsheetNovembre_2022_R,
    factsheetNovembre_2023_I,
    factsheetNovembre_2023_R,
    factsheetOttobre_2022_I,
    factsheetOttobre_2022_R,
    factsheetOttobre_2023_I,
    factsheetOttobre_2023_R,
    factsheetOttobre_2024_I_IT,
    factsheetOttobre_2024_R_IT,
    factsheetSettembre_2022_I,
    factsheetSettembre_2022_R,
    factsheetSettembre_2023_I,
    factsheetSettembre_2023_R,
    factsheet_2024_Agosto_I_EN,
    factsheet_2024_Agosto_I_IT,
    factsheet_2024_Agosto_R_EN,
    factsheet_2024_Agosto_R_IT,
    factsheet_2024_Aprile_I_EN,
    factsheet_2024_Aprile_I_IT,
    factsheet_2024_Aprile_R_EN,
    factsheet_2024_Aprile_R_IT,
    factsheet_2024_Giugno_I_EN,
    factsheet_2024_Giugno_I_IT,
    factsheet_2024_Giugno_R_EN,
    factsheet_2024_Giugno_R_IT,
    factsheet_2024_Luglio_I_EN,
    factsheet_2024_Luglio_I_IT,
    factsheet_2024_Luglio_R_EN,
    factsheet_2024_Luglio_R_IT,
    factsheet_2024_Maggio_I_EN,
    factsheet_2024_Maggio_I_IT,
    factsheet_2024_Maggio_R_EN,
    factsheet_2024_Maggio_R_IT,
    factsheet_2024_Settembre_I_EN,
    factsheet_2024_Settembre_I_IT,
    factsheet_2024_Settembre_R_EN,
    factsheet_2024_Settembre_R_IT,
    prospetto,
    semiAnnualReport_sett_2022,
    semiAnnualReport_sett_2023,
    semiAnnualReport_sett_2024,
    sottoscrizionePers,
    sottoscrizioneSoc,
    websiteDisclosure,
  ]);

  return (
    <div className="container wrapper-documents pt-5 mt-5 mb-5 pb-5">
      <div className="internal-wrap pt-5 mt-5 pb-5 mb-5">
        <div className="rigsave-custom-title text-center pb-5">
          {t("main.fundManagement.documentsTitle")}
        </div>
        <div className="text-center pb-5 mb-4">
          <input
            type="text"
            className="input-search"
            onChange={handleInputChange}
            value={searchValue}
          />
        </div>
        {filteredData ? (
          <div className="results-wrapper">
            {filteredData.map((pdf, i) => {
              return (
                <div
                  key={i}
                  className="col-lg-6 card-documents px-5 pt-4 pb-4 mx-5 mb-5"
                >
                  <div className="title-documents">{pdf.name}</div>
                  <Link
                    to={pdf.url}
                    className="button-contact final-a list-inline d-block text-center"
                  >
                    PDF
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="container justify-content-center text-center">
            <RotatingLines
              strokeColor="#6b97cf"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Documents;

import React from "react";

import { Trans, useTranslation } from "react-i18next";

import "../css/Certificates.css";
import { Link } from "react-router-dom";

import certificatesImg from "../assets/photos/certificates.jpg";

function Certificates() {
  const { t } = useTranslation();

  return (
    <div className="certificates pt-5 mt-5 pb-5 mb-5">
      <div className="container container-title pt-5 pb-5 mb-4">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.certificates.title")}
        </div>
      </div>
      <div className="wrapper-certificates container d-lg-flex justify-content-center px-5 pb-5">
        <img src={certificatesImg} alt="Financial consulting" />
        <div className="text px-5">
          <p>{t("main.certificates.text")}</p>
          <div className="d-flex justify-content-center pt-4">
            <Link
              to={"/contact-us"}
              className="button-contact list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
      </div>
      <div className="container container-what-is pt-5 mt-5 pb-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.certificates.consultingFor")}
        </div>
        <div className="text">
          <Trans>{t("main.certificates.consultingForText")}</Trans>
        </div>
      </div>
      <div className="wrapper-certificates container pt-5">
        <div className="rigsave-custom-title text-center pb-4 mb-5">
          {t("main.certificates.advantages")}
        </div>
        <div className="wrapper-divs d-lg-flex justify-content-center pb-5">
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.certificates.compProf")}</p>
            <p className="text">{t("main.certificates.compProfText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.certificates.commissioni")}</p>
            <p className="text">{t("main.certificates.commissioniText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">
              {t("main.certificates.investmentOptimization")}
            </p>
            <p className="text">
              {t("main.certificates.investmentOptimizationText")}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-5 mt-5 mb-5 pb-5">
          <Link
            to={"/contact-us"}
            className="button-contact final-a list-inline d-block text-center"
          >
            {t("main.home.contactUs")}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Certificates;

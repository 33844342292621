import React from "react";

import { Trans, useTranslation } from "react-i18next";

import "../css/FundManagement.css";
import { Link } from "react-router-dom";

import fundManagementImg from "../assets/photos/fund_management.jpg";
import icon1 from "../assets/icons/icon_home_1.png";
import icon2 from "../assets/icons/icon_home_2.png";
import icon3 from "../assets/icons/icon_home_3.png";

function FundManagement() {
  const { t } = useTranslation();

  return (
    <div className="fund-management pt-5 mt-5 pb-5 mb-5">
      <div className="container container-title pt-5 pb-5 mb-4">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.fundManagement.title")}
        </div>
      </div>
      <div className="wrapper-fund-management container d-lg-flex justify-content-center px-5 pb-5">
        <img src={fundManagementImg} alt="Financial consulting" />
        <div className="text px-5">
          <p>{t("main.fundManagement.text")}</p>
          <div className="d-flex justify-content-center pt-4">
            <Link
              to={"/contact-us"}
              className="button-contact list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
      </div>
      <div className="container container-what-is pt-5 mt-5 pb-5">
        <div className="rigsave-custom-title text-center pb-4">
          {t("main.fundManagement.consultingFor")}
        </div>
        <div className="text">
          <Trans>{t("main.fundManagement.consultingForText")}</Trans>
        </div>
      </div>
      <div className="container justify-content-center wrapper-what-is-icons">
        <div className="wrapper-icons-home-meths">
          <div className="d-lg-flex wrapper-text-what-is-icons">
            <div className="col-lg-4">
              <img
                className="iconHome-icon"
                src={icon1}
                alt="Raccolta del capitale"
              />
            </div>
            <div className="col-lg-4">
              <img
                className="iconHome-icon"
                src={icon2}
                alt="Investimento su portafoglio diversificato"
              />
            </div>
            <div className="col-lg-4">
              <img
                className="iconHome-icon"
                src={icon3}
                alt="Capitalizzazione dei rendimenti"
              />
            </div>
          </div>
          <div className="d-lg-flex wrapper-text-what-is-icons-2 pt-4">
            <div className="col-lg-4 text-center">
              <p>{t("main.home.raccolta")}</p>
            </div>
            <div className="col-lg-4 text-center">
              <p>{t("main.home.investimento")}</p>
            </div>
            <div className="col-lg-4 text-center">
              <p>{t("main.home.capitalizzazione")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-lg-flex justify-content-center container-investment px-4 pt-4 mt-5 mb-5 pb-4">
        <div className="col-lg-6 px-5">
          <p className="title">{t("main.fundManagement.unaTantum")}</p>
          <Trans>{t("main.fundManagement.unaTantumText")}</Trans>
        </div>
        <div className="col-lg-6 px-5">
          <p className="title">{t("main.fundManagement.pianoAccumulo")}</p>
          <Trans>{t("main.fundManagement.pianoAccumuloText")}</Trans>
        </div>
      </div>
      <div className="wrapper-fund-management container pt-5">
        <div className="rigsave-custom-title text-center pb-4 mb-5">
          {t("main.fundManagement.advantages")}
        </div>
        <div className="wrapper-divs d-lg-flex justify-content-center pb-5">
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.fundManagement.compProf")}</p>
            <p className="text">{t("main.fundManagement.compProfText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">{t("main.fundManagement.commissioni")}</p>
            <p className="text">{t("main.fundManagement.commissioniText")}</p>
          </div>
          <div className="col-lg-3 wrap-card px-4 pt-4 pb-4 mx-5">
            <p className="title">
              {t("main.fundManagement.investmentOptimization")}
            </p>
            <p className="text">
              {t("main.fundManagement.investmentOptimizationText")}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-5 mt-5 pb-5">
          <Link
            to={"/contact-us"}
            className="button-contact final-a list-inline d-block text-center"
          >
            {t("main.home.contactUs")}
          </Link>
        </div>
        <div className="container justify-content-center wrapper-doc px-5 mt-5">
          <div className="title text-center pt-4">
            {t("main.fundManagement.documents")}
          </div>
          <div className="text pt-3">
            {t("main.fundManagement.documentsText")}
          </div>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <Link
              to={"/fund-management/documents"}
              className="button-contact final-a list-inline d-block text-center"
            >
              {t("main.home.read")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundManagement;
